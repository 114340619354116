  import React, { useRef, useState, useEffect, useCallback } from "react";
  import { useSelector, useDispatch } from "react-redux";
  import { useLocation } from "react-router-dom";
  import { Header } from "../../components/Header/Header";
  import {
    fetchChatStream,
    resetChatState,
    updatePartialAnswer,
  } from "../../Features/chatSlice";
  import "./contractChat.css";
  import "./FolderChat.css";
  import axios from "axios";
  import { useNavigate } from "react-router-dom";
  const FolderChat = () => {
    const chatContainerRef = useRef(null);
    const [isCopyPopupVisible, setCopyPopupVisible] = useState(false);
    const [inputMessage, setInputMessage] = useState("");
    const [showScrollButton, setShowScrollButton] = useState(false);
    const location = useLocation();
    const [copiedMessageId, setCopiedMessageId] = useState(null);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const folderId = location.state?.folderId;
    const pdfList = location.state?.pdfList || [];
    const [selectedPdf, setSelectedPdf] = useState(pdfList[0]?.pdfUrl || "");
    const [thumbsUpClicked, setThumbsUpClicked] = useState({});
    const [thumbsDownClicked, setThumbsDownClicked] = useState({});
    const [clickedIndex, setClickedIndex] = useState(null);
    const [clientMessage, setClientMessage] = useState("");
    const [isSummaryComplete, setIsSummaryComplete] = useState(false);
    const dispatch = useDispatch();
    const partialAnswer = useSelector((state) => state.chat.partialAnswer);
    const finalAnswer = useSelector((state) => state.chat.finalAnswer);
    const status = useSelector((state) => state.chat.status);
    const error = useSelector((state) => state.chat.error);
    const { botMessageId, runId, generatedQuestions } = useSelector(
      (state) => state.chat
    );
    const [showDummyMessages, setShowDummyMessages] = useState(true);
    const [typedDummyMessages, setTypedDummyMessages] = useState([]);
    const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
    const [isThinking, setIsThinking] = useState(false);
    console.log("Generated Questions:", generatedQuestions);
    const [textAreaHeight, setTextAreaHeight] = useState("auto");
    const textareaRef = useRef(null);
    const [isSummaryLoading, setIsSummaryLoading] = useState(true);
    const [isSummaryCompleted, setIsSummaryCompleted] = useState(false);
    const { pdfUrl, pdfKey } = location.state || {};
    const [chatHistory, setChatHistory] = useState([]);
    const [activeFolder, setActiveFolder] = useState(null);
    const [isFetchingSummary, setIsFetchingSummary] = useState(false);
    const [isFolderEmptyPopupVisible, setIsFolderEmptyPopupVisible] = useState(false);

    const formatText = useCallback((input) => {
      if (typeof input !== "string") {
        console.error("Invalid input to formatText:", input);
        return "";
      }
      let formattedText = input
        .replace(/\n/g, "<br/>")
        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
        .replace(/__(.*?)__/g, "<u>$1</u>")
        .replace(/~~(.*?)~~/g, "<del>$1</del>")
        .replace(/###(.*?)/g, "<strong>$1</strong>")
        .replace(/^-\s(.+)/gm, "<li>$1</li>")
        .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
        .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");
    
      return `<p>${formattedText}</p>`;
    }, []);
    
    console.log("bot", botMessageId);
    console.log("run", runId);

    useEffect(() => {
      const handleScroll = () => {
        const shouldShow =
          chatContainerRef.current.scrollTop <
          chatContainerRef.current.scrollHeight -
            chatContainerRef.current.clientHeight -
            100;
        setShowScrollButton(shouldShow);
      };

      const container = chatContainerRef.current;
      if (container) {
        container.addEventListener("scroll", handleScroll);
      }
      return () => {
        if (container) {
          container.removeEventListener("scroll", handleScroll);
        }
      };
    }, []);
    const submitFeedback = async () => {
      try {
        if (!botMessageId || !runId || !selectedFeedbackType) {
          console.error("Missing required data for feedback submission.");
          return;
        }

        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/contract/feedback/`,
          {
            message_id: botMessageId,
            run_id: runId,
            feedback_type: selectedFeedbackType,
            user_feedback: feedbackMessage || "",
          },
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.msg === "Feedback recorded successfully.") {
          console.log("Feedback submitted successfully!");
          setShowFeedbackModal(false);
        } else {
          console.error("Feedback submission failed:", response.data.msg);
        }
      } catch (error) {
        console.error("Error submitting feedback:", error);
      }
    };
    const navigate = useNavigate();
    const handleCloseEmptyFolderPopup = () => {
      setIsFolderEmptyPopupVisible(false);
      navigate("/uploadFile"); 
  };
  

    const handleThumbsUpClick = (messageId) => {
      setThumbsUpClicked((prev) => ({
        ...prev,
        [messageId]: !prev[messageId],
      }));

      setThumbsDownClicked((prev) => ({
        ...prev,
        [messageId]: false,
      }));

      if (!thumbsUpClicked[messageId]) {
        setPopupVisible(true);
        setMessageIdToFeedback(messageId);
        setRunIdToFeedback(runId);
        setSelectedFeedbackType("up");
        submitFeedback();
      }
    };

    const handleThumbsDownClick = (messageId) => {
      setThumbsDownClicked((prev) => ({
        ...prev,
        [messageId]: !prev[messageId],
      }));
      setThumbsUpClicked((prev) => ({
        ...prev,
        [messageId]: false,
      }));

      if (!thumbsDownClicked[messageId]) {
        setMessageIdToFeedback(messageId);
        setRunIdToFeedback(runId);
        setSelectedFeedbackType("down");
        setShowThumbsDownPopup(true);
      }
    };
    const streamText = (text, callback, delay = 5) => {
      let charIndex = 0;

      const stream = () => {
        if (charIndex < text.length) {
          callback(text.slice(0, charIndex + 1));
          charIndex++;
          setTimeout(stream, delay);
        }
      };

      stream();
    };

    const [hasSummaryBeenAdded, setHasSummaryBeenAdded] = useState(false);
    const fetchController = useRef(null); 

    useEffect(() => {
      const fetchFolderSummaryAndQuestions = async () => {
        if (hasSummaryBeenAdded || !folderId) return;
    
        try {
          setIsSummaryLoading(true);
          setIsThinking(true);
          setIsSummaryCompleted(false);
          if (fetchController.current) {
            fetchController.current.abort();
          }
          fetchController.current = new AbortController();
    
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/readpdf/folder-summary-and-questions/${folderId}/`,
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
              signal: fetchController.current.signal,
            }
          );
    
          if (response.data.msg === "Folder is empty. Please upload files to chat.") {
            setIsFolderEmptyPopupVisible(true); // Show the popup
            setIsSummaryLoading(false);
            setIsSummaryCompleted(false);
            return; // Exit early
          }
    
          const { meta_summary, sample_questions } = response.data;
    
          setChatHistory((prev) => {
            if (!prev.some((msg) => msg.text === meta_summary)) {
              return [
                ...prev,
                {
                  id: Date.now(),
                  sender: "bot",
                  text: meta_summary,
                  final: true,
                },
              ];
            }
            return prev;
          });
    
          if (sample_questions) {
            const questions = sample_questions.split(";").map((q) => q.trim());
            streamDummyMessages(questions.slice(0, 2));
          }
    
          setIsThinking(false);
          setIsSummaryLoading(false);
          setIsSummaryCompleted(true);
        } catch (error) {
          if (axios.isCancel(error)) {
            console.warn("Previous API call aborted");
          } else {
            console.error("Error fetching folder summary and questions:", error);
          }
        }
      };
    
      fetchFolderSummaryAndQuestions();
    
      return () => {
        if (fetchController.current) {
          fetchController.current.abort();
        }
      };
    }, [folderId]);
    

    useEffect(() => {
      if (partialAnswer) {
        setChatHistory((prev) => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage?.sender === "bot" && !lastMessage.final) {
            lastMessage.text = partialAnswer;
            return [...prev];
          } else if (
            !prev.some(
              (msg) => msg.sender === "bot" && msg.text === partialAnswer
            )
          ) {
            return [
              ...prev,
              { sender: "bot", text: partialAnswer, final: false },
            ];
          }
          return prev;
        });
      }
    }, [partialAnswer]);

    useEffect(() => {
      if (finalAnswer) {
        setChatHistory((prev) => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage?.sender === "bot" && !lastMessage.final) {
            lastMessage.text = finalAnswer;
            lastMessage.final = true;
            return [...prev];
          } else if (
            !prev.some((msg) => msg.sender === "bot" && msg.text === finalAnswer)
          ) {
            return [...prev, { sender: "bot", text: finalAnswer, final: true }];
          }
          return prev;
        });
        setIsAwaitingResponse(false);
        setIsThinking(false);

        if (generatedQuestions) {
          const questions = generatedQuestions.split(";").map((q) => q.trim());
          streamDummyMessages(questions.slice(0, 2));
        }

        setShowDummyMessages(true);
      }
    }, [finalAnswer, generatedQuestions]);

    const handleCopy = (messageId, text) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopiedMessageId(messageId);
          setTimeout(() => setCopiedMessageId(null), 2000);
        })
        .catch((err) => {
          console.error("Failed to copy message:", err);
        });
    };

    const [selectedCheckboxes, setSelectedCheckboxes] = useState({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });

    const handleCheckboxChange = (e) => {
      const { id, checked } = e.target; 
      setSelectedCheckboxes((prev) => ({
        ...prev,
        [id]: checked, 
      }));
    };
    const handleTextareaChange = (e) => {
      setFeedbackMessage(e.target.value); 
    };
    

    const handleSubmitThumbsDownFeedback = async () => {
      const selectedFeedback = [];
      if (selectedCheckboxes.factuallyIncorrect) {
        selectedFeedback.push("Not factually correct.");
      }
      if (selectedCheckboxes.refused) {
        selectedFeedback.push("Refused when it shouldn’t have.");
      }
      if (selectedCheckboxes.notFollowingInstructions) {
        selectedFeedback.push("Not Following Instructions.");
      }
      const combinedFeedback = [...selectedFeedback, feedbackMessage].join("\n");

      setFeedbackMessage(combinedFeedback);

      try {
        await submitFeedback();
        setSelectedCheckboxes({
          factuallyIncorrect: false,
          refused: false,
          notFollowingInstructions: false,
        });
        setFeedbackMessage("");

        setShowThumbsDownPopup(false);
      } catch (error) {
        console.error("Error submitting feedback:", error);
      }
    };

    const handleClosePopup = () => {
      setShowThumbsDownPopup(false);
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");
    };

    useEffect(() => {
      return () => {
        dispatch(resetChatState());
      };
    }, [dispatch]);

    const isSubmitDisabled = () => {
      return (
        !feedbackMessage.trim() && 
        !Object.values(selectedCheckboxes).some((checked) => checked)
      );
    };

    const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);

    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
    const [runIdToFeedback, setRunIdToFeedback] = useState(null);

    const streamDummyMessages = (questions) => {
      
      const maxToShow = 2;
      const limitedQuestions = questions.slice(0, maxToShow);
      setTypedDummyMessages(new Array(maxToShow).fill(""));

      limitedQuestions.forEach((question, index) => {
        let charIndex = 0;
        const interval = setInterval(() => {
          setTypedDummyMessages((prev) => {
            const updatedMessages = [...prev];
            updatedMessages[index] = question.slice(0, charIndex + 1);
            return updatedMessages;
          });

          charIndex += 1;

          if (charIndex === question.length) clearInterval(interval);
        }, 10);
      });
    };

    const handleSend = () => {
      if (!isSummaryCompleted || isAwaitingResponse) return;
      if (inputMessage.trim()) {
        setChatHistory((prev) => [
          ...prev,
          { id: Date.now(), sender: "user", text: inputMessage },
          { id: Date.now(), sender: "bot", text: "", final: false },
        ]);
        setIsAwaitingResponse(true);
        setIsThinking(true);
        setInputMessage("");
        setClientMessage("");
        setTextAreaHeight("auto");
        setShowDummyMessages(false);
        setClickedIndex(null);
        if (textareaRef.current) {
          textareaRef.current.style.height = "auto";
        }
        dispatch(fetchChatStream({ query: inputMessage, folderId }));

        setShowDummyMessages(false);
        setClickedIndex(null);
      } else {
        console.error("Message cannot be empty");
      }
    };

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = textAreaHeight;
      }
      return () => {
        if (textareaRef.current) {
          textareaRef.current.style.height = textAreaHeight;
        }
      };
    }, [textAreaHeight]);

    useEffect(() => {
      if (status === "loading" && !partialAnswer) {
        setIsThinking(true);
      } else if (status === "succeeded" && partialAnswer) {
        setIsThinking(false);
      } else if (status === "failed") {
        setIsThinking(false);
      }
    }, [status, partialAnswer]);

    const handleDummyMessageClick = (dummyMessage, index) => {
      if (dummyMessage.trim()) {
        setClickedIndex(index);
        setInputMessage(dummyMessage.trim());
      }
    };

    const scrollToBottom = () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    };
    useEffect(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, [chatHistory]);

    useEffect(() => {
      if (chatHistory[chatHistory.length - 1]?.sender === "bot") {
      }
    }, [chatHistory]);

    return (
      <>
        <div className="full-container">
          <div className="custom-header-wrapper">
            <Header icon="/arrow-back.svg" name="Go to Documents" />
          </div>
          <div className="content-container">
            <div className="left-side">
              <div className="message-container" ref={chatContainerRef}>
              {isSummaryLoading && (
  <div className="bot-message-container w-full flex items-start mb-4">
    <div className="bot-icon-container flex-shrink-0">
      <img
        src="/bot-icon.svg"
        alt="Bot Icon"
        className="bot-icon h-6 w-6 rounded-full mr-2"
      />
    </div>
    <div className="thinking-indicator mb-4 flex gap-1">
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </div>
  </div>
)}


                {chatHistory.map((message, index) => (
                  <div
                    key={message.id}
                    className={`mb-2 ${
                      message.sender === "user"
                        ? "w-full flex justify-end"
                        : "bot-message-container w-full flex items-start"
                    }`}
                  >
                    {message.sender === "bot" && (
                      <div className="bot-icon-container flex-shrink-0">
                        <img
                          src="/bot-icon.svg"
                          alt="Bot Icon"
                          className="bot-icon h-6 w-6 rounded-full mr-2"
                        />
                      </div>
                    )}
                    {message.sender === "bot" ? (
                      <div className="flex flex-col">
                        {isThinking &&
                        index === chatHistory.length - 1 &&
                        !message.text ? (
                          <div className="thinking-indicator mb-4">
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                          </div>
                        ) : (
                          <span
                            className="bot-message-content"
                            dangerouslySetInnerHTML={{
                              __html: formatText(message.text),
                            }}
                          />
                        )}
                        <div className="reactions flex gap-2 text-gray-500 mt-2">
                          <span
                            className="reaction-icon cursor-pointer"
                            onClick={() => handleThumbsUpClick(message.id)}
                          >
                            <img
                              src={
                                thumbsUpClicked[message.id]
                                  ? "/thums-up-filled.svg"
                                  : "/thums-up.svg"
                              }
                              alt="Thumbs Up"
                            />
                          </span>

                          <span
                            className="reaction-icon cursor-pointer"
                            onClick={() => handleThumbsDownClick(message.id)}
                          >
                            <img
                              src={
                                thumbsDownClicked[message.id]
                                  ? "/thums-down-filled.svg"
                                  : "/thums-down.svg"
                              }
                              alt="Thumbs Down"
                            />
                          </span>
                          <span
                            className="reaction-icon cursor-pointer"
                            onClick={() => handleCopy(message.id, message.text)}
                          >
                            <img
                              src={
                                copiedMessageId === message.id
                                  ? "/copy-clicked.svg"
                                  : "/copy.svg"
                              }
                              alt="Copy"
                            />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <span className="user-message-bubble">{message.text}</span>
                    )}
                  </div>
                ))}
                {status === "failed" && (
                  <div className="bot-message-container w-full flex items-start mb-4">
                    <div className="bot-message-content text-red-600 text-sm p-4 max-w-[85%]">
                      <strong>Error:</strong> {error}
                    </div>
                  </div>
                )}
              </div>
              <div className="dummy-container flex flex-col gap-2 md:gap-2 mr-20 ">
                {showDummyMessages &&
                  typedDummyMessages.map((question, index) => (
                    <div
                      key={index}
                      className={`dummy-message-box border border-purple-400 rounded-lg p-4 mt-4 flex items-center cursor-pointer ${
                        clickedIndex === index ? "opacity-50" : "opacity-100"
                      }`}
                      onClick={() =>
                        handleDummyMessageClick(question.trim(), index)
                      }
                    >
                      <span className="icon mr-2">
                        <img src="/star-dot.svg" alt="Star Icon" />
                      </span>
                      <p className="text-sm">{question.trim()}</p>
                    </div>
                  ))}
              </div>
              <div className="custom-input-wrapper">
  <div className="input-container-holder">
    <textarea
      placeholder={
        isSummaryLoading || !isSummaryCompleted
          ? "Loading summary, please wait..." 
          : "Ask me anything"
      }
      className="input-field-holder w-4/5 min-h-[22px] resize-none text-[#3f3f46] font-outfit text-lg font-semibold leading-6 ml-[35px] mt-[17px] border-0 outline-none bg-transparent overflow-hidden max-h-[88px] overflow-y-auto"
      value={inputMessage}
      onChange={(e) => {
        if (isSummaryCompleted && !isAwaitingResponse) {
          setInputMessage(e.target.value);
          if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${Math.min(
              e.target.scrollHeight,
              88
            )}px`;
          }
        }
      }}
      onKeyDown={(e) => {
        if (
          e.key === "Enter" &&
          !e.shiftKey &&
          isSummaryCompleted &&
          !isAwaitingResponse
        ) {
          e.preventDefault();
          if (inputMessage.trim()) {
            handleSend();
            setInputMessage("");
          }
        }
      }}
      ref={textareaRef}
      disabled={isSummaryLoading || !isSummaryCompleted || isAwaitingResponse}
      />

<button
  className="send-button-holder"
  onClick={() => handleSend()}
  disabled={isSummaryLoading || !isSummaryCompleted || isAwaitingResponse}
>
  {isSummaryLoading ? (
    <img src="/loading-send-button.svg" alt="Loading" />
  ) : isAwaitingResponse ? (
    <img src="/loading-send-button.svg" alt="Loading" />
  ) : (
    <img src="/send.svg" alt="Send" />
  )}
</button>

  </div>
</div>
{isFolderEmptyPopupVisible && (
  <div className="popup-container-folderchat">
    <div className="popup-content-folderchat">
      <h2>Folder is Empty</h2>
      <p>Please copy files to this folder to start chatting.</p>
      <button
        className="close-popup-button-folderchat"
        onClick={() => {
          console.log("Close button clicked");
          handleCloseEmptyFolderPopup(); 
        }}
      >
        Close
      </button>
    </div>
  </div>
)}

              {showScrollButton && (
                <button
                  onClick={scrollToBottom}
                  className="scroll-to-bottom fixed bottom-16 right-4 bg-blue-500 text-white rounded-full"
                ></button>
              )}
            </div>
            <div className="right-side-folder">
              <div className="iframe-folder">
                <iframe
                  src={selectedPdf}
                  title="PDF Viewer"
                  className="w-full h-3/4"
                  allow="fullscreen"
                />
              </div>

              <div className="folder-list-container">
                <div className="folder-list-scrollable">
                  {pdfList.map((pdf) => (
                    <div
                      key={pdf.pdfKey}
                      className="folder-box"
                      onClick={() => {
                        setSelectedPdf(pdf.pdfUrl);
                        setActiveFolder(pdf.pdfKey);
                      }}
                    >
                        <div
          className={`folder-icon-container ${
            activeFolder === pdf.pdfKey ? "active" : ""
          }`}
        >
                        <img
                          src="/pdf.svg"
                          alt={pdf.name}
                          className="folder-icon"
                        />
                      </div>
                      <div className="folder-container">
                        <p className="folder-name">{pdf.name}</p>
                        <span className="folderTooltip">{pdf.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {showThumbsDownPopup && (
            <div className="popup-container-thumsdown">
              <div className="popup-content-thumsdown">
                <div>
                  <div>
                    <button
                      className="close-button-thumsdown"
                      onClick={() => setShowThumbsDownPopup(false)}
                    >
                      <p>x</p>
                    </button>
                  </div>
                  <div className="paragraph-container">
                    <p className="paragraph-container-para1">What went Wrong?</p>
                    <p className="paragraph-container-para2">
                      Select feedback that reflects how well this response
                      addressed your question or concern.
                    </p>
                    <div class="checkbox-container-thumsup">
                      <label className="thumsup-checkbox">
                        <input
                          type="checkbox"
                          className="thumbsup1"
                          id="factuallyIncorrect"
                          checked={selectedCheckboxes.factuallyIncorrect}
                          onChange={handleCheckboxChange}
                        />
                        Not factually correct.
                      </label>

                      <label className="thumsup-checkbox">
                        <input
                          type="checkbox"
                          className="thumbsup2"
                          id="refused"
                          checked={selectedCheckboxes.refused}
                          onChange={handleCheckboxChange}
                        />
                        Refused when it shouldn’t have.
                      </label>

                      <label className="thumsup-checkbox">
                        <input
                          type="checkbox"
                          className="thumbsup3"
                          id="notFollowingInstructions"
                          checked={selectedCheckboxes.notFollowingInstructions}
                          onChange={handleCheckboxChange}
                        />
                        Not Following Instructions.
                      </label>
                    </div>
                    <div class="line-with-text">
                      <span>Or</span>
                    </div>
                    <div class="rectangular-box">
                      <div class="feedback-container">
                        <textarea
                          className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                          maxLength="250"
                          placeholder="Your feedback is much appreciated and helps improve the AI!"
                          value={feedbackMessage}
                          onChange={(e) => setFeedbackMessage(e.target.value)}
                        />
                      </div>
                      <p class="character-count">Maximum: 250 characters</p>
                    </div>
                    <button
                      className={`custom-button-thumsdown ${
                        isSubmitDisabled() ? "disabled-button-thumsdown" : "enabled-button-thumsdown"
                      }`}
                      onClick={handleSubmitThumbsDownFeedback}
                      disabled={isSubmitDisabled()}
                    >
                      Submit
                    </button>{" "}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  export default FolderChat;
