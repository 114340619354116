import React, { useState } from "react";
import "./forgotPassword.css";
import logo from "../../../Images/Logo.png";
import axios from "axios";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import BackLogo from "../../../Images/arrow_back.jpg";
import errorEmailLogo from '../../../Images/emailerror.png'

import forgetLogo from "../../../Images/forgot-logo.jpg";
export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [verified, setVarified] = useState(false);
  const [userEmail, setUserEmail] = useState(""); // Hold the obfuscated email

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/accounts/request-password-reset/`, // API URL from .env
        { 
          email: email 
        }, // Send the email in the request body
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:", response);

      if (response.data) {
        const { uid, token } = response.data;
        // const resetLink = `https://docusensa.com/reset-password/${uid}/${token}/`;

        const resetLink = `${process.env.REACT_APP_API_URL}/api/v1/accounts/reset-password/${uid}/${token}/`;

        // Display success message with the reset link
        setSuccessMessage(
          `Password reset link sent to ${email}. Check your email and use the following link: ${resetLink}`
        );

      // Hide the link, just notify the user to check their email
      // setSuccessMessage(`Password reset link sent to ${email}. Check your email.`);
      setVarified(true); // Show the success div
      }
    } catch (error) {
      // If the user doesn't exist or there was an error, display the error message
      if (error.response && error.response.data) {
        setErrorMessage(
          // error.response.data.message ||
          //   "Something went wrong. Please try again."
          "Email does not exist."
        );
      } else {
        setErrorMessage("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenEmailApp = () => {
    window.location.href = "mailto:"; // This will open the default email app
  };

  return (
    <div className="page-container">
      <span className="main">
        <img className="logo-section" src={logo} alt="docuSensa logo" />
      </span>
      <div className="forgot-password-container">
        <div className="forgot-password-box flex justify-center items-center flex-col">
          {verified ? (
            <>
              <div className="flex justify-center items-center">
                <img
                  src={forgetLogo}
                  alt="Logo"
                  className="logo w-[120px] mb-5"
                />
              </div>

              <div className="flex justify-center items-center flex-col">
                <div className="forgot-text">
                  <h2 className="email-heading">Check your email</h2>
                  <p className="email-subtitle ">
                  We Sent a Password reset link to<br/><strong>{email.replace(/(.{2}).+(@.+)/, "$1********$2")}</strong>
                  </p>
                </div>

                
                <button
                  className="reset-password-btn mb-7"
                  onClick={handleOpenEmailApp}
                >
                  Open email app
                </button>

                  <p className="resend-link">
                    Didn’t receive the email?{" "}
                    <span className="resend-email" onClick={handleSubmit}>Click to resend</span>
                  </p>
                
              </div>

              <div className="flex items-center justify-center">
                <Link to="/signin" className="back-to-login flex items-center">
                  <img src={BackLogo} alt="back logo" className="mr-2" />
                  <span>Back to log in</span>
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-center items-center">
                <img
                  src={forgetLogo}
                  alt="Logo"
                  className="logo w-[120px] mb-5"
                />
              </div>
              <div className="flex w-[325px] flex-col gap-4 items-center">
                <h2 className="forgot-title">Forgot your password?</h2>
                <p className="forgot-subtitle">
                  No Worries, We’ll send you reset instructions
                </p>
              </div>

              <form
                onSubmit={handleSubmit}
                className="forgot-form flex flex-col gap-8 w-[568px] items-start"
              >
                <div className="email-container flex flex-col gap-2 items-start">
                  <label htmlFor="email" className="email-label flex">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Enter your Registered Email"
                    className="email-input"
                    required
                  />
                  {errorMessage && (
                <p className="flex items-center">
                  <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span>{errorMessage}
                </p>
                )}
                </div>
                
                <button
                  type="submit"
                  className="reset-password-btn"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Reset password"}
                </button>
              </form>
              {/* {errorMessage && <p className="error-message">{errorMessage}</p>} */}
              

              <div className="flex items-center justify-center">
                <Link to="/signin" className="back-to-login flex items-center">
                  <img src={BackLogo} alt="back logo" className="mr-2" />
                  <span>Back to log in</span>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
