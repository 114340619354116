import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";  // Import useParams and useNavigate hook
import axios from "axios";
import "./createPassword.css";
import logo from "../../../Images/Logo.png";
import forgetLogo from "../../../Images/forgot-logo.jpg";
import { Link } from "react-router-dom";
import BackLogo from "../../../Images/arrow_back.jpg";
import errorEmailLogo from "../../../Images/emailerror.png";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import checklogo from '../../../Images/check-logo.jpg'
export const CreatePassword = () => {
    const { uid, token } = useParams();  // Get uid and token from URL

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successfullyReset, setSuccessfullyReset] = useState(false)
  const navigate = useNavigate();  // Hook for navigation after success




  const [loading, setLoading] = useState(false); // Define loading state
  const [email, setEmail] = useState(""); // Define email state
  const [verified, setVerified] = useState(false);

const handleNavigateLogin = () =>{
  navigate("/signin")
}

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleResendMail = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    // setSuccessMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/accounts/request-password-reset/`, // API URL from .env
        { 
          email: email 
        }, // Send the email in the request body
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:", response);

      if (response.data) {
        const { uid, token } = response.data;
        // const resetLink = `https://docusensa.com/reset-password/${uid}/${token}/`;

        const resetLink = `${process.env.REACT_APP_API_URL}/api/v1/accounts/reset-password/${uid}/${token}/`;

        // Display success message with the reset link
        // setSuccessMessage(
        //   `Password reset link sent to ${email}. Check your email and use the following link: ${resetLink}`
        // );

      // Hide the link, just notify the user to check their email
      // setSuccessMessage(`Password reset link sent to ${email}. Check your email.`);
      setVerified(true); // Show the success div
      }
    } catch (error) {
      // If the user doesn't exist or there was an error, display the error message
      if (error.response && error.response.data) {
        setErrorMessage(
          // error.response.data.message ||
          //   "Something went wrong. Please try again."
          "Email does not exist."
        );
      } else {
        setErrorMessage("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
        setErrorMessage("New password is required.");
        return;
      }
  
      if (password !== confirmPassword) {
        setErrorMessage("Passwords don’t match.");
        return;
      }


    // if (password !== confirmPassword) {
    //   setErrorMessage("Password doesn’t match");
    // } else if (!confirmPassword) {
    //   setErrorMessage("Confirm your password");
    // } else {
    //   // Submit password reset logic here
    //   setSuccessMessage("Password reset successfully!");
    // }

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/v1/accounts/reset-password/${uid}/${token}/`, // API URL from .env
             // Send the email in the request body
             { 
                new_password: password 
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
          );
    
        //   const data = await response.json();
          console.log("reset password data",response)
          if (response.status === 200) {
            setSuccessfullyReset(true);
            // Password reset successful
            // setSuccessMessage("Password has been reset successfully.");
            // setErrorMessage("");  // Clear error message
            // setTimeout(() => {
            //   navigate("/signin");  // Redirect to sign in after a successful reset
            // }, 2000);  // Redirect after 2 seconds
          } else {
            // Handle error responses
            setErrorMessage(response.data.message || "An error occurred.");
            // setSuccessMessage(""); 
          }
        } catch (error) {
          // This will catch any network or server errors
            if (error.response) {
                // Server responded with an error code
                setErrorMessage(error.response.data.message || "Something went wrong. Please try again later.");
            } else {
                // Other errors like network issues
                setErrorMessage("Something went wrong. Please try again later.");
            }
        }
  };

  return (
    <div className="page-container">
      <span className="main">
        <img className="logo-section" src={logo} alt="docuSensa logo" />
      </span>
      {successfullyReset ? (
         // Success page
         <div className="success-page">
         <div className="success-icon">
           <img src={checklogo} alt="Success Icon" className="w-[64px] h-[64px]"/>
         </div>
         <div className="flex w-[325px] flex-col items-center gap-4">
            <h1>Password reset</h1>
            <p>Your password has been successfully reset. Click below to log in.</p>
         </div>
         <div>
         <button onClick={handleNavigateLogin} className="back-login-btn flex justify-center items-center rounded-xl mb-7">
           Back to login
         </button>
        </div>
         {/* <div>
            <p className="resend-link">
                Didn’t receive the email?{" "}
                <span className="resend-email" onClick={handleResendMail}>Click to resend</span>
            </p>
            {loading && <p>Loading...</p>}
            {errorMessage && <p>{errorMessage}</p>}
         </div> */}
       </div>
      ): (
        <div className="reset-password-container">
        <div className="reset-password-box flex justify-center items-center flex-col">
          <div className="flex justify-center items-center">
            <img src={forgetLogo} alt="Logo" className="logo w-[120px] mb-5" />
          </div>
          <div className="flex w-[325px] flex-col gap-4 items-center">
            <h1 className="reset-title">Set your new password</h1>
            <p className="subtitle">
              Your new password must be different from your previous password
            </p>
          </div>

          <form
            className="reset-form flex flex-col w-[568px] items-start"
            onSubmit={handleSubmit}
          >
            <div className="form-group">
              <label htmlFor="password" className="block mb-2">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter here"
                  value={password}
                  onChange={handlePasswordChange}
                  className="password-input p-6"
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="show-pass absolute top-5 text-gray-500"
                >
                  {showPassword ? (
                    <IoEyeOutline size={24} />
                  ) : (
                    <IoEyeOffOutline size={24} />
                  )}
                </button>
              </div>
              <p className="input-hint mt-2">Must be at least 8 characters</p>
            </div>

            <div className="form-group">             
              <label htmlFor="confirm-password" className="block mb-2">Confirm password</label>
              <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Enter here"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                className="password-input p-6"
                required
              />
               <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="show-pass absolute top-5 text-gray-500"
                  >
                    {showConfirmPassword ? <IoEyeOutline size={24} /> : <IoEyeOffOutline size={24} /> }
                </button>
                </div>
              {errorMessage && (
                <p className="flex items-center mt-2">
                  <span className="w-[20px] h-[20px] mr-1">
                    <img src={errorEmailLogo} alt="email error logo" />
                  </span>
                  {errorMessage}
                </p>
              )}
            </div>

            {/* {errorMessage && <p className="error-message">{errorMessage}</p>}
                    {successMessage && <p className="success-message">{successMessage}</p>} */}

            <button type="submit" className="reset-password-btn">
              Reset password
            </button>
          </form>

          <div className="back-link flex items-center justify-center">
            <button  className="back-to-login flex items-center" onClick={handleNavigateLogin}>
              <img src={BackLogo} alt="back logo" className="mr-2" />
              <span>Back to log in</span>
            </button>
          </div>
          {/* <div className="back-link">
                    <a href="/login">
                    <span>&larr;</span> Back to log in
                    </a>
                </div> */}
        </div>
      </div>
      )}
    </div>
  );
};
