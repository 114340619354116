import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchChatContract,
  resetChatContractState,
  updatePartialAnswer,
} from "../../Features/chatContractSlice";
import { sendFeedback } from "../../Features/sendFeedback";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Header } from "../../components/Header/Header";
import "./contractChat.css";

const ContractChat = () => {
  const chatContainerRef = useRef(null);
  const [isCopyPopupVisible, setCopyPopupVisible] = useState(false);
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [typedQuestions, setTypedQuestions] = useState([]);
  const [showDummyMessages, setShowDummyMessages] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [shouldStopResponse, setShouldStopResponse] = useState(false); 
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [clientMessage, setClientMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([
    { id: Date.now(), sender: "bot", text: "Summary is loading. Please wait..." },
  ]);
  const [contractUrl, setContractUrl] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const finalAnswer = useSelector((state) => state.pdfChat?.finalAnswer || "");
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [clickedIndex, setClickedIndex] = useState(null); 
  const [isThinking, setIsThinking] = useState(false);
  const [isSummaryCompleted, setIsSummaryCompleted] = useState(false);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);
  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const textareaRef = useRef(null);
  const [typingStatus, setTypingStatus] = useState(false); 
  const [isSummaryLoading, setIsSummaryLoading] = useState(true); 
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const contractKey = new URLSearchParams(location.search).get("ckey") || "";
  const { botMessageId, runId, generatedQuestions } = useSelector(
    (state) => state.chatContract
  );

  console.log("id", botMessageId);
  const partialAnswer = useSelector(
    (state) => state.chatContract.partialAnswer
  );
  const status = useSelector((state) => state.chatContract.status);
  const error = useSelector((state) => state.chatContract.error);

  useEffect(() => {
    setIsThinking(false);
    const fetchContractUrl = async () => {
      
      if (!contractKey) return;
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/contract/view-contract/${contractKey}/`,
          
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
              
            },
          }
        );
        setIsThinking(false);
        if (response.status === 200 && response.data.contract_url) {
          setContractUrl(response.data.contract_url);
          setIsThinking(false);
        } else {
          console.error("Contract URL not found.");
        }
      } catch (error) {
        console.error("Error fetching contract URL:", error);
      }
    };

    fetchContractUrl();
  }, [contractKey]);
  const [isSummaryComplete, setIsSummaryComplete] = useState(false);


  useEffect(() => {
    const fetchSummary = async () => {
      if (!contractKey) return;
  
      try {
        setIsSummaryLoading(true);
        setIsSummaryCompleted(false);
  
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/contract/summary-and-questions/?contract_key=${contractKey}`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        const { summary, example_questions } = response.data;
  
        setChatHistory((prev) => [
          { id: Date.now(), sender: "bot", text: summary },
          ...prev.slice(1),
        ]);
  
        if (example_questions) {
          const questions = example_questions.split(";").map((q) => q.trim());
          setTypedQuestions(questions.slice(0, 2)); 
        }
  
        setIsSummaryCompleted(true);
        setIsSummaryLoading(false);
      } catch (error) {
        console.error("Error fetching summary:", error);
        setIsSummaryLoading(false);
      }
    };
  
    fetchSummary();
  }, [contractKey]);
  
  const typeQuestion = null; 


  useEffect(() => {
    if (generatedQuestions) {
      const questions = generatedQuestions.split(";").map((q) => q.trim());
      const limitedQuestions = questions.slice(0, 2); 
      setTypedQuestions(limitedQuestions); 
      setShowDummyMessages(true); 
    }
  }, [generatedQuestions]);
  
  


  const [placeholder, setPlaceholder] = useState("Ask me anything");

  const handleSend = (message = clientMessage) => {
    if (isAwaitingResponse || isSummaryLoading) return;
  
    if (message.trim() && contractKey) {
      const userMessage = { id: Date.now(), sender: "user", text: message };
  
      setChatHistory((prev) => [...prev, userMessage]);
  
      setChatHistory((prev) => [
        ...prev,
        { id: Date.now(), sender: "bot", text: "", final: false },
      ]);
  
      setIsThinking(true);
      setIsAwaitingResponse(true);
  
      dispatch(fetchChatContract({ contractKey, clientMessage: message }))
        .finally(() => {
          setIsAwaitingResponse(false);
          setIsThinking(false);
        });
  
      setClientMessage(""); 
      setTextAreaHeight("auto");
      setShowDummyMessages(false);
      setClickedIndex(null);
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }
    }
  };
  
  
  
  useEffect(() => {
    if (isSummaryLoading) {
      setIsThinking(true);
    } else if (status === "succeeded" || status === "failed") {
      setIsThinking(false);
      setIsAwaitingResponse(false);
    }
  }, [status, isSummaryLoading]);
  


  useEffect(() => {
    if (partialAnswer) {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = partialAnswer;
          return [...prev];
        }
        return prev;
      });
      setIsThinking(false);
    }
  }, [partialAnswer]);

  useEffect(() => {
    if (finalAnswer) {
      setChatHistory((prev) => [
        ...prev,
        { id: Date.now(), sender: "bot", text: finalAnswer },
      ]);
      setIsAwaitingResponse(false);
      setIsThinking(false);
  
      if (generatedQuestions) {
        const questions = generatedQuestions.split(";").map((q) => q.trim());
        setTypedQuestions(questions.slice(0, 2));
        setShowDummyMessages(true);
      }
    }
  }, [finalAnswer, generatedQuestions]);
  

  useEffect(() => {
    if (textareaRef.current) { 
      textareaRef.current.style.height = textAreaHeight; 
    }
    return () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = textAreaHeight; 
      }
    };
  }, [textAreaHeight]);

  const handleInputBackspace = () => {
    if (clientMessage.length > 0) {
    
      setClientMessage(clientMessage.slice(0, -1));
    }
  };



  const formatText = useCallback((input) => {
    let formattedText = input
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/###(.*?)/g, "<strong>$1</strong>")
      .replace(/^-\s(.+)/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");

    formattedText = formattedText.replace(/\n/g, "<br/>");

    return `<p style="white-space: pre-wrap; word-wrap: break-word; overflow-wrap: break-word;">${formattedText}</p>`;
  }, []);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    return () => {
      dispatch(resetChatContractState());
    };
  }, [dispatch]);
  const handleThumbsUpClick = (messageId) => {
    const isCurrentlyClicked = thumbsUpClicked[messageId];
    
    
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: !isCurrentlyClicked, 
    }));
    
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: false, 
    }));
  
   
    if (!isCurrentlyClicked) {
      setPopupVisible(true);
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("up");
      submitFeedback();
    }
  };
  

  const handleThumbsDownClick = (messageId) => {
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId], 
    }));
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: false,
    }));

    if (!thumbsDownClicked[messageId]) {
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("down");
      setShowThumbsDownPopup(true);
    }
  };

  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId); 
        setTimeout(() => setCopiedMessageId(null), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };

  useEffect(() => {
    if (finalAnswer) {
      setThumbsUpClicked({}); 
    }
  }, [finalAnswer]);

  const submitFeedback = async () => {
    try {
      if (!botMessageId || !runId || !selectedFeedbackType) {
        console.error("Missing required data for feedback submission.");
        return; 
      }

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/feedback/`,
        {
          message_id: botMessageId,
          run_id: runId, 
          feedback_type: selectedFeedbackType, 
          user_feedback: feedbackMessage || "", 
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.msg === "Feedback recorded successfully.") {
        console.log("Feedback submitted successfully!");
        setShowFeedbackModal(false);
      } else {
        console.error("Feedback submission failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target; 
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [id]: checked, 
    }));
  };
  const handleTextareaChange = (e) => {
    setFeedbackMessage(e.target.value); 
  };

  
  const handleSubmitThumbsDownFeedback = async () => {
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
      selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
      selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
      selectedFeedback.push("Not Following Instructions.");
    }
    const combinedFeedback = [...selectedFeedback, feedbackMessage].join("\n");

    setFeedbackMessage(combinedFeedback); 

    try {
      await submitFeedback();
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");
      setShowThumbsDownPopup(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const isSubmitDisabled = () => {
    return (
      !feedbackMessage.trim() && 
      !Object.values(selectedCheckboxes).some((checked) => checked)
    );
  };
  
  const handleClosePopup = () => {
    setPopupVisible(false); 
    setShowThumbsDownPopup(false);

    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });

    setFeedbackMessage(""); 
  };

  const inputField = document.querySelector(".custom-input-field-contract");
  const inputContainer = document.querySelector(".custom-input-container");

  const handleInput = () => {
    
    inputField.style.height = "auto";

    if (inputField.scrollHeight <= 88) {
      inputField.style.height = `${inputField.scrollHeight}px`; 
      inputContainer.style.height = `${inputField.scrollHeight + 20}px`; 
    } else {
      inputField.style.height = "88px"; 
      inputContainer.style.height = "88px"; 
    }
  };

  if (inputField) {
    inputField.addEventListener("input", handleInput);
  }

  useEffect(() => {
    
    if (chatHistory[chatHistory.length - 1]?.sender === "bot") {
      
    }
  }, [chatHistory]);

  useEffect(() => {
    if (chatHistory[chatHistory.length - 1]?.sender === "bot") {
     
    }
  }, [chatHistory]);

  useEffect(() => {
    console.log("Status updated:", status);
    if (status === "loading") {
     
      setShowDummyMessages(false);
    } else if (status === "succeeded" || status === "failed") {
      
      setShowDummyMessages(true);
    }
  }, [status]); 

const streamText = (text, callback, delay = 30) => {
  let charIndex = 0;
  const interval = setInterval(() => {
    const chunk = text.slice(0, charIndex + 1);
    callback(chunk);

    charIndex += 1;
    if (charIndex >= text.length) {
      clearInterval(interval);
    }
  }, delay);
};

  

  return (
    <div className="full-container-chat">
      <div className="">
        <Header icon="/arrow-back.svg" name="Go to Contracts" />
      </div>
      <div className="content-container">
       
        <div className="left-side">
          <div className="message-container" ref={chatContainerRef}>
          {chatHistory.map((message, index) => (
  <div
    key={index}
    className={`mb-2 ${
      message.sender === "user"
        ? "w-full flex justify-end"
        : "bot-message-container w-full flex items-start"
    }`}
  >
    {message.sender === "bot" && (
      <div className="bot-icon-container flex-shrink-0">
        <img
          src="/bot-icon.svg"
          alt="Bot Icon"
          className="bot-icon h-6 w-6 rounded-full mr-2"
        />
      </div>
    )}

{message.sender === "bot" ? (
                  <div className="flex flex-col">
                    {isThinking && index === chatHistory.length - 1  ? (
                      <div className="thinking-indicator mb-4">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    ) : (
                      <span
                        className="bot-message-content"
                        dangerouslySetInnerHTML={{
                          __html: formatText(message.text),
                        }}
                      />
                    )}
        <div className="reactions flex gap-2 text-gray-500 mt-2">
          <span
            className="reaction-icon cursor-pointer"
            onClick={() => handleThumbsUpClick(message.id)}
          >
            <img
              src={
                thumbsUpClicked[message.id]
                  ? "/thums-up-filled.svg"
                  : "/thums-up.svg"
              }
              alt="Thumbs Up"
            />
          </span>
          <span
            className="reaction-icon cursor-pointer"
            onClick={() => handleThumbsDownClick(message.id)}
          >
            <img
              src={
                thumbsDownClicked[message.id]
                  ? "/thums-down-filled.svg"
                  : "/thums-down.svg"
              }
              alt="Thumbs Down"
            />
          </span>
          <span
            className="reaction-icon cursor-pointer"
            onClick={() => handleCopy(message.id, message.text)}
          >
            <img
              src={
                copiedMessageId === message.id
                  ? "/copy-clicked.svg"
                  : "/copy.svg"
              }
              alt="Copy"
            />
          </span>
        </div>
      </div>
    ) : (
      <span className="user-message-bubble">{message.text}</span>
    )}
  </div>
))}

  
            {status === "failed" && (
              <div className="bot-message-container w-full flex items-start mb-4">
                <div className="bot-message-content text-red-600 text-sm p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}
          </div>
          <div className="dummy-container flex flex-col gap-2 md:gap-2 mr-20">
  {showDummyMessages &&
    typedQuestions.map((question, index) => (
      <div
        key={index}
        className={`dummy-message-box border border-purple-400 rounded-lg p-4 flex items-center cursor-pointer ${
          clickedIndex === index ? "opacity-50" : "opacity-100"
        }`}
        onClick={() => {
          setClickedIndex(index);
          setClientMessage(question.trim());
        }}
      >
        <span className="icon mr-2">
          <img src="/star-dot.svg" alt="Star Icon" />
        </span>
        <p className="text-sm">{question.trim()}</p>
      </div>
    ))}
</div>

  
          <div className="custom-input-wrapper">
            <div className="input-container-holder">
            <textarea
      placeholder={isSummaryLoading ? "Summary is loading..." : "Ask me anything"}
      className="input-field-holder w-4/5 min-h-[22px] resize-none text-[#3f3f46] font-outfit text-lg font-semibold leading-6 ml-[35px] mt-[17px] border-0 outline-none bg-transparent overflow-hidden max-h-[88px] overflow-y-auto"
  value={clientMessage}
  onChange={(e) => {
    if (!isAwaitingResponse && !isSummaryLoading) { 
      setClientMessage(e.target.value);
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto"; 
        textareaRef.current.style.height = `${Math.min(
          e.target.scrollHeight,
          88 
        )}px`;
      }
    }
  }}
  onKeyDown={(e) => {
    if (e.key === "Enter" && !e.shiftKey && !isAwaitingResponse && !isSummaryLoading) {
      e.preventDefault();
      if (clientMessage.trim()) {
        handleSend(clientMessage.trim());
        setClientMessage("");
      }
    }
  }}
  ref={textareaRef}
  disabled={isAwaitingResponse || isSummaryLoading} 
/>



<button
  className="send-button-holder"
  onClick={() => {
    setTextAreaHeight("15px");
    handleSend();
  }}
  disabled={isAwaitingResponse || isSummaryLoading}
>
<img
        src={
          isSummaryLoading
            ? "/loading-send-button.svg"
            : isAwaitingResponse
            ? "/loading-send-button.svg"
            : "/send.svg"
        }
        alt="Send"
      />
</button>




            </div>
          </div>
        </div>
        <div className="right-side">
          <iframe
            src={contractUrl}
            title="PDF Viewer"
            className="w-full h-full"
            allow="fullscreen"
          />
        </div>
      </div>
  
      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            <div>
              <div>
                <button
                  className="close-button-thumsdown"
                  onClick={() => setShowThumbsDownPopup(false)}
                >
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed
                  your question or concern.
                </p>
                <div className="checkbox-container-thumsup">
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup1"
                      id="factuallyIncorrect"
                      checked={selectedCheckboxes.factuallyIncorrect}
                      onChange={handleCheckboxChange}
                    />
                    Not factually correct.
                  </label>
  
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup2"
                      id="refused"
                      checked={selectedCheckboxes.refused}
                      onChange={handleCheckboxChange}
                    />
                    Refused when it shouldn’t have.
                  </label>
  
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup3"
                      id="notFollowingInstructions"
                      checked={selectedCheckboxes.notFollowingInstructions}
                      onChange={handleCheckboxChange}
                    />
                    Not Following Instructions.
                  </label>
                </div>
                <div className="line-with-text">
                  <span>Or</span>
                </div>
                <div className="rectangular-box">
                  <div className="feedback-container">
                    <textarea
                      className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                      value={feedbackMessage}
                      maxlength="250"
                      placeholder="Your feedback is much Appreciated and helps improve the AI!"
                      onChange={(e) => setFeedbackMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <p className="character-count">Maximum: 250 characters</p>
                </div>
  
                <button
                   className={`custom-button-thumsdown ${
                    isSubmitDisabled() ? "disabled-button-thumsdown" : "enabled-button-thumsdown"
                  }`}
                  onClick={handleSubmitThumbsDownFeedback}
                  disabled={isSubmitDisabled()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  
};

export default ContractChat;
