import React, { useState, useEffect,useCallback  } from 'react';
import axios from 'axios';
import './uploadModal.css';
import { useNavigate } from 'react-router-dom';

const UploadModal = ({ isOpen, onClose,onUploadSuccess, type ,folderId }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  let progressInterval = null;
  const token = localStorage.getItem('token');
  const fetchNewContractId = useCallback(async () => {
    if (!token) return;
  
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/contracts/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
  
      if (response.data && response.data.length > 0) {
        
        const highestId = Math.max(...response.data.map(contract => contract.id));
        const newId = highestId + 1; 
        console.log('Next Contract ID:', newId);
        return newId;
      } else {
        console.log('No contracts found, starting from ID 1');
        return 1; 
      }
    } catch (error) {
      console.error('Error fetching contracts:', error);
      return null; // Handle error case
    }
  }, [token]);
  

  const navigate = useNavigate();
  const [contractKey, setContractKey] = useState(null);

  if (!isOpen) return null;

  const triggerFileInput = () => document.getElementById('fileInput').click();

  const handleViewDetails = () => {
    const pdfFile = file; 

    navigate('/Contract/contract-details', { state: { contractKey, pdfFile } });
  };



  const startProgress = () => {

    progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 90) {
          return prevProgress + 1;
        } else {
          clearInterval(progressInterval);
          return prevProgress;
        }
      });
    }, 100); 
  };



  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setIsUploading(true);
    setIsUploaded(false);
    setProgress(0);
    startProgress();
    handleFileUpload(selectedFile);
  };


  const handleFileUpload = async (selectedFile) => {
    const token = localStorage.getItem('token');
    console.log("here",selectedFile, folderId)

    if (!token) {
      alert("Session expired, please log in.");
      onClose();
      return;
    }

    if (!selectedFile || (type === 'underFolder' && !folderId)) return;

    const formData = new FormData();
    formData.append('file', selectedFile);

    let url = '';

    // Check for the type and set the URL accordingly
    if (type === 'contract') {
      url = `${process.env.REACT_APP_API_URL}/api/v1/contract/qdrant-upload-contract/`;
    } else if (type === 'underFolder' ) {
      url = `${process.env.REACT_APP_API_URL}/api/v1/readpdf/upload-in-folder/${folderId}/`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/api/v1/readpdf/qdrant-upload-async/`;
    }
    console.log("vg", url)

    try {
      const response = await axios.post(
        url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${token}`
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.min(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
              90
            );
            setProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200) {
        clearInterval(progressInterval);
        setTimeout(() => {
          setProgress(100);
          setTimeout(() => {
            setIsUploaded(true);
            setIsUploading(false);
            setContractKey(response.data.contract_key); 
          }, 1000); 
        }, 500);

      } else {
        alert(response.data.msg || "File upload failed");
        setIsUploading(false);
      }
    } catch (error) {
      console.log("Upload error:", error);
      alert(error.response?.data.msg || "An error occurred during file upload. Please try again.");
      setIsUploading(false);
    }
  };
  const handleClose = () => {
    onClose();
    if (type === 'contract') {
      navigate('/Contract');
    } else if (type === 'underFolder') {
      navigate(`/folder/?folderId=${folderId}`);
    } else {
      navigate('/uploadFile');
    }
  };
  

  
  


  return (
    <div className="modal-overlay-details">
      <div className="modal-content-details">
      <button className="close-button" onClick={handleClose} style={{ marginRight: '30px' ,fontSize: '22px'}}>
  x
</button>
        {isUploaded ? (
          <div>
            <div className="success-status">
              <div className="success-icon-upload">
                <img src="/right-success.svg" alt="Success Icon" className="checkmark-icon" />
                
              </div>
              {type === 'contract' ? 
                <h3 className="success-heading">Contract Uploaded Successfully</h3> : 
                type === 'underFolder' ? 
                <h3 className="success-heading">File Uploaded to Folder Successfully</h3> : 
                <h3 className="success-heading">File Uploaded Successfully</h3>}


{type === 'contract' ? 
                <p className="success-message-upload">Your contract has been uploaded successfully</p> :
                type === 'underFolder' ? 
                <p className="success-message">Your file has been uploaded to the folder successfully</p> : 
                <p className="success-message">Your File has been uploaded successfully</p>}
              <div className="file-info">
                <span className="file-name-icon"><img src="file-icon.svg" alt="" /></span>
                <span className="file-name">{file ? file.name : "Uploaded_File.pdf"}</span>
              </div>
            </div>
            {type === 'contract' && (
  <p className="extraction-message">
    Data has been extracted from <a href="#" className="extraction-link">{file ? file.name : "Uploaded_File.pdf"}</a>
  </p>
)}




            {type === 'contract' ? (
  <button className="view-details-button" onClick={handleViewDetails}>
    View Details
  </button>
) : type === 'underFolder' ? (
  <button className="view-details-button-doc mt-6" onClick={handleClose}>
    Done
  </button>
) : (
  <button className="view-details-button-doc" onClick={handleClose}>
    Done
  </button>
)}




          
          </div>
        ) : isUploading ? (
          <div className="Full-Box">
          {type === "contract" ? (
            <h3 className="Heading1">Upload Your Contract</h3>
          ) : type === "underFolder" ? (
            <h3 className="Heading1">Upload Your File to Folder</h3>
          ) : (
            <h3 className="Heading1">Upload Your Files</h3>
          )}
    
          {type === "contract" ? (
            <p className="Paragraph1">Upload your contract here and start extracting data</p>
          ) : type === "underFolder" ? (
            <p className="Paragraph1">Upload your file here and store it in a folder</p>
          ) : (
            <p className="Paragraph1">Upload your file here and start extracting data</p>
          )}
    
          <div className="upload-box">
            <img src="/uploading.svg" alt="" className="uploadingIcon" />
            <p className="status-text-upload">File is being uploaded</p>
            <p className="sub-status-text">
              {type === "contract"
                ? "Your contract is being uploaded"
                : type === "underFolder"
                ? "Your file is being uploaded to the folder"
                : "Your file is being uploaded"}
            </p>
            <p className="progress-label">Your Progress</p>
            <p className="progress-percentage-upload">{progress}% to complete</p>
            <div className="time-estimate">
              <span className="time-icon">⏱</span> 1min
            </div>
            <div className="progress-bar-cont">
              <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
            </div>
          </div>
    
          <div className="file-info">
            <div className="file-info-container">
              <div className="support" style={{ position: "relative", left: "160px" }}>
                Supported formats: PDF
              </div>
            </div>
            <div className="file-info-container">
              <div
                className="maximum"
                style={{
                  position: "relative",
                  left: "-10px", 
                }}
              >
                Maximum Size: 20MB
              </div>
            </div>
          </div>
        </div>
        ) :(
          <div className="Full-Box">
            {type === 'contract' ? 
              <h3 className="Heading1">Upload Your Contract</h3> : 
              type === 'underFolder' ? 
              <h3 className="Heading1">Upload Your File to Folder</h3> : 
              <h3 className="Heading1">Upload Your Files</h3>}
            
            {type === 'contract' ? 
              <p className="Paragraph1">Upload your contract here and start extracting data</p> :
              type === 'underFolder' ? 
              <p className="Paragraph1">Upload your file here and store it in a folder</p> :
              <p className="Paragraph1">Upload your file here and start extracting data</p>}
            
            <div className="upload-box" onClick={triggerFileInput}>
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".pdf"
              />
              <div className="upload-icon">
                <img src="/uploadBig.svg" alt="Upload Icon" className="upload-big-icon" />
                <img src="/uploadSmall.svg" alt="Overlay Icon" className="upload-small" />
              </div>
              <p className="main-text">Click to upload your files or <span className="choose-file">Choose File</span></p>
            </div>
        
            <div className="file-info">
              <div className="file-info-container">
                <div className="support" style={{ position: 'relative', left: '10rem' }}>
                  Supported formats: PDF
                </div>
              </div>
              <div className="file-info-container">
                <div
                  className="maximum"
                  style={{
                    position: 'relative',
                    left: '-15px',
                  }}
                >Maximum Size: 20MB</div>
              </div>
            </div>


          </div>
        )}
      </div>
    </div>  
  );
};

export default UploadModal;