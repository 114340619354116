import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchChatStream = createAsyncThunk(
  "chat/fetchChatStream",
  async ({ folderId, query }, { rejectWithValue, dispatch }) => {
    const token =
      localStorage.getItem("token");

    if (!token) {
      return rejectWithValue("No authorization token found");
    }

    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/readpdf/chat-with-folder/?folder_id=${folderId}`;

    const headers = {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify({ query }),
      });

      if (!response.body) {
        return rejectWithValue("No response body received from the server");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      
      let accumulatedAnswer = '';
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.trim().split('\n');
        for (const line of lines) {
          if (!line.trim()) continue;
          try {
            const jsonLine = line.startsWith('data: ') ? line.slice(6).trim() : line.trim();
            const parsedLine = JSON.parse(jsonLine);
            if (parsedLine.ans) {
              accumulatedAnswer += parsedLine.ans;
              dispatch(updatePartialAnswer(accumulatedAnswer));
            }
            if (parsedLine.type === 'final') {
              
              botMessageId = parsedLine.data.bot_message_id;
              runId = parsedLine.data.run_id;
              generatedQuestions = parsedLine.data.generated_questions;
              dispatch(updateFinalAnswer(accumulatedAnswer));
              dispatch(updateBotMessageInfo({ botMessageId, runId, generatedQuestions, })); 
              return accumulatedAnswer;
            }
          } catch (error) {
            console.error('Error parsing line:', line, error);
          }
        }
      }
      return accumulatedAnswer; 
    } catch (error) {
      console.error('Error during PDF chat:', error);
      return rejectWithValue(error.message || 'An error occurred while fetching the PDF chat response');
    }
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    messages: [],
    partialAnswer: '',
    finalAnswer: '',
    status: 'idle',
    error: null,
    botMessageId: null, 
    runId: null,
    generatedQuestions: null,
  },
  reducers: {
    resetChatState: (state) => {
      state.messages = [];
      state.partialAnswer = '';
      state.finalAnswer = '';
      state.status = 'idle';
      state.error = null;
      state.botMessageId = null;
      state.runId = null; 
      state.generatedQuestions = null;
    },
    updatePartialAnswer: (state, action) => {
      state.partialAnswer = action.payload;
    },
    updateFinalAnswer: (state, action) => {
      state.finalAnswer = action.payload;
    },
    updateBotMessageInfo: (state, action) => {
      const { botMessageId, runId, generatedQuestions } = action.payload;
      state.botMessageId = botMessageId;
      state.runId = runId;
      state.generatedQuestions = generatedQuestions;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatStream.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChatStream.fulfilled, (state, action) => {
        state.messages.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(fetchChatStream.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
      });
  },
});

export const {  updatePartialAnswer, updateFinalAnswer, updateBotMessageInfo, resetChatState } =
  chatSlice.actions;

export default chatSlice.reducer;
