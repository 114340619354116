import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPdfChat,
  resetPdfChatState,
  updatePartialAnswer,
} from "../../Features/pdfChatSlice";
import { Header } from "../../components/Header/Header";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./chat.css";
import "../Contract/contractChat.css";

const PdfChat = () => {
  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);
  const [inputMessage, setInputMessage] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const status = useSelector((state) => state.chat.status);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const textareaRef = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const [chatHistory, setChatHistory] = useState([
    { id: Date.now(), sender: "bot", text: "Summary is loading. Please wait..." },
  ]);
  const partialAnswer = useSelector(
    (state) => state.pdfChat?.partialAnswer || ""
  );
  const finalAnswer = useSelector((state) => state.pdfChat?.finalAnswer || "");
  const error = useSelector((state) => state.pdfChat?.error);
  const { botMessageId, runId, generatedQuestions } = useSelector(
    (state) => state.pdfChat
  );
  const location = useLocation();
  const [isCopyPopupVisible, setCopyPopupVisible] = useState(false);
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [typedDummyMessages, setTypedDummyMessages] = useState([]);
  const { pdfKey: dynamicPdfKey, pdfUrl: dynamicPdfUrl } = location.state || {};
  const pdfKey = dynamicPdfKey || " ";
  const pdfUrl = dynamicPdfUrl || " ";
  const formatText = useCallback((input) => {
    let formattedText = input
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/###(.*?)/g, "<strong>$1</strong>")
      .replace(/^-\s(.+)/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");
    return `<p>${formattedText}</p>`;
  }, []);
  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);
  const [clientMessage, setClientMessage] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);
  const [showDummyMessages, setShowDummyMessages] = useState(true);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [isSummaryCompleted, setIsSummaryCompleted] = useState(false);

  const handleSend = (messageToSend) => {
    if (isAwaitingResponse || isSummaryLoading) return;
    const message = messageToSend || clientMessage;
    if (message.trim() && pdfKey) {
      const userMessage = { id: Date.now(), sender: "user", text: message };
      setChatHistory((prev) => [...prev, userMessage]);
      setChatHistory((prev) => [
        ...prev,
        { id: Date.now(), sender: "bot", text: "", final: false },
      ]);
      setIsThinking(true);
      setIsAwaitingResponse(true);
      dispatch(fetchPdfChat({ pdfKey, clientMessage: message }));
      setClientMessage("");
      setTextAreaHeight("auto");
      setShowDummyMessages(false);
      setClickedIndex(null);
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }
    }
  };

  useEffect(() => {
    if (status === "loading" && !partialAnswer) {
      setIsThinking(true);
    } else if (partialAnswer) {
      setIsThinking(false);
    }
  }, [status, partialAnswer]);

  useEffect(() => {
    if (status === "succeeded" || status === "failed") {
      setIsThinking(false);
    }
  }, [status]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = textAreaHeight;
    }
    return () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = textAreaHeight;
      }
    };
  }, [textAreaHeight]);

  useEffect(() => {
    const fetchSummary = async () => {
      if (pdfKey) {
        try {
          setIsSummaryLoading(true);
          setIsSummaryCompleted(false);
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/readpdf/summary-and-questions/?pdf_key=${pdfKey}`,
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          const { summary, example_questions } = response.data;
          setChatHistory((prev) => [
            { id: Date.now(), sender: "bot", text: summary }, 
            ...prev.slice(1),
          ]);
          setIsSummaryCompleted(true);
          setIsSummaryLoading(false);
          

          if (example_questions) {
            const questions = example_questions.split(";").map((q) => q.trim());
            const limitedQuestions = questions.slice(0, 2);
            setTypedDummyMessages(new Array(2).fill(""));
            limitedQuestions.forEach((question, index) => {
              streamText(
                question,
                (chunk) => {
                  setTypedDummyMessages((prev) => {
                    const updatedMessages = [...prev];
                    updatedMessages[index] = chunk;
                    return updatedMessages;
                  });
                },
                5
              );
            });
          }
          setIsSummaryLoading(false);
          setIsAwaitingResponse(false);
        } catch (error) {
          console.error("Error fetching summary and questions:", error);
        } finally {
          setIsSummaryLoading(false); 
        }

      }
    };
    fetchSummary();
  }, [pdfKey]);

  const handleDummyMessageClick = (dummyMessage, index) => {
    if (dummyMessage.trim()) {
      setClickedIndex(index);
      setClientMessage(dummyMessage.trim());
    }
  };

  const isSubmitDisabled = () => {
    return (
      !feedbackMessage.trim() && 
      !Object.values(selectedCheckboxes).some((checked) => checked)
    );
  };

  const streamDummyMessages = (questions) => {
    const maxToShow = 2;
    const limitedQuestions = questions.slice(0, maxToShow);
    setTypedDummyMessages(new Array(maxToShow).fill(""));
    limitedQuestions.forEach((question, index) => {
      let charIndex = 0;
      const interval = setInterval(() => {
        setTypedDummyMessages((prev) => {
          const updatedMessages = [...prev];
          updatedMessages[index] = question.slice(0, charIndex + 1);
          return updatedMessages;
        });
        charIndex += 1;
        if (charIndex === question.length) clearInterval(interval);
      }, 10);
    });
  };

  const streamText = (text, callback, delay = 10) => {
    let charIndex = 0;
    const interval = setInterval(() => {
      const chunk = text.slice(0, charIndex + 1);
      callback(chunk);
      charIndex += 1;
      if (charIndex >= text.length) {
        clearInterval(interval);
      }
    }, delay);
  };

  useEffect(() => {
    if (partialAnswer) {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = partialAnswer;
          return [...prev];
        } else if (
          !prev.some(
            (msg) => msg.sender === "bot" && msg.text === partialAnswer
          )
        ) {
          return [
            ...prev,
            { sender: "bot", text: partialAnswer, final: false },
          ];
        }
        return prev;
      });
    }
  }, [partialAnswer]);

  useEffect(() => {
    if (finalAnswer) {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = finalAnswer;
          lastMessage.final = true;
          return [...prev];
        } else if (
          !prev.some((msg) => msg.sender === "bot" && msg.text === finalAnswer)
        ) {
          return [...prev, { sender: "bot", text: finalAnswer, final: true }];
        }
        return prev;
      });
      setIsAwaitingResponse(false);
      setIsThinking(false);
      if (generatedQuestions) {
        const questions = generatedQuestions.split(";").map((q) => q.trim());
        streamDummyMessages(questions.slice(0, 2));
      }
      setShowDummyMessages(true);
    }
  }, [finalAnswer, generatedQuestions]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const submitFeedback = async () => {
    try {
      if (!messageIdToFeedback || !runIdToFeedback || !selectedFeedbackType) {
        console.error("Missing required data for feedback submission.");
        return;
      }
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/feedback/`,
        {
          message_id: messageIdToFeedback,
          run_id: runIdToFeedback,
          feedback_type: selectedFeedbackType,
          user_feedback: feedbackMessage || "",
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.msg === "Feedback recorded successfully.") {
        console.log("Feedback submitted successfully!");
        setShowFeedbackModal(false);
      } else {
        console.error("Feedback submission failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleThumbsUpClick = (messageId) => {
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: false,
    }));
    if (!thumbsUpClicked[messageId]) {
      setPopupVisible(true);
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("up");
      submitFeedback();
    }
  };

  const handleThumbsDownClick = (messageId) => {
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: false,
    }));
    if (!thumbsDownClicked[messageId]) {
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("down");
      setShowThumbsDownPopup(true);
    }
  };

  useEffect(() => {
    if (status === "succeeded") {
      const newBotMessage = {
        id: Date.now(),
        sender: "bot",
        text: finalAnswer,
      };
      setChatHistory((prev) => [...prev, newBotMessage]);
    }
  }, [status, finalAnswer]);

  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target; 
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [id]: checked, 
    }));
  };
  const handleTextareaChange = (e) => {
    setFeedbackMessage(e.target.value); 
  };
  

  const handleSubmitThumbsDownFeedback = async () => {
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
      selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
      selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
      selectedFeedback.push("Not Following Instructions.");
    }
    const combinedFeedback = [...selectedFeedback, feedbackMessage].join("\n");
    setFeedbackMessage(combinedFeedback);
    try {
      await submitFeedback();
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");
      setShowThumbsDownPopup(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setShowThumbsDownPopup(false);
    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });
    setFeedbackMessage("");
  };

  useEffect(() => {
    return () => {
      dispatch(resetPdfChatState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (generatedQuestions) {
      const questions = generatedQuestions.split(";").map((q) => q.trim());
      streamDummyMessages(questions);
    }
  }, [generatedQuestions]);

  useEffect(() => {
    if (
      chatHistory.length > 0 &&
      chatHistory[chatHistory.length - 1]?.sender === "bot"
    ) {
      const newMessageId = chatHistory[chatHistory.length - 1]?.id;
      setThumbsUpClicked((prev) => ({
        ...prev,
        [newMessageId]: false,
      }));
      setThumbsDownClicked((prev) => ({
        ...prev,
        [newMessageId]: false,
      }));
    }
  }, [chatHistory]);

  return (
    <div className="full-container">
      <div className="custom-header-wrapper">
        <Header icon="/arrow-back.svg" name="Go to Documents" />
      </div>
      <div className="content-container">
        <div className="left-side">
          <div className="message-container" ref={chatContainerRef}>
            {chatHistory.map((message, index) => (
              <div
                key={index}
                className={`mb-2 ${
                  message.sender === "user"
                    ? "w-full flex justify-end"
                    : "bot-message-container w-full flex items-start"
                }`}
              >
                {message.sender === "bot" && (
                  <div className="bot-icon-container flex-shrink-0">
                    <img
                      src="/bot-icon.svg"
                      alt="Bot Icon"
                      className="bot-icon h-6 w-6 rounded-full mr-2"
                    />
                  </div>
                )}
                {message.sender === "bot" ? (
                  <div className="flex flex-col">
                    {isThinking && index === chatHistory.length - 1 ? (
                      <div className="thinking-indicator mb-4">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    ) : (
                      <span
                        className="bot-message-content"
                        dangerouslySetInnerHTML={{
                          __html: formatText(message.text),
                        }}
                      />
                    )}
                    <div className="reactions flex gap-2 text-gray-500 mt-2">
                      <span
                        className="reaction-icon cursor-pointer"
                        onClick={() => handleThumbsUpClick(message.id)}
                      >
                        <img
                          src={
                            thumbsUpClicked[message.id]
                              ? "/thums-up-filled.svg"
                              : "/thums-up.svg"
                          }
                          alt="Thumbs Up"
                        />
                      </span>
                      <span
                        className="reaction-icon cursor-pointer"
                        onClick={() => handleThumbsDownClick(message.id)}
                      >
                        <img
                          src={
                            thumbsDownClicked[message.id]
                              ? "/thums-down-filled.svg"
                              : "/thums-down.svg"
                          }
                          alt="Thumbs Down"
                        />
                      </span>
                      <span
                        className="reaction-icon cursor-pointer"
                        onClick={() => handleCopy(message.id, message.text)}
                      >
                        <img
                          src={
                            copiedMessageId === message.id
                              ? "/copy-clicked.svg"
                              : "/copy.svg"
                          }
                          alt="Copy"
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  <span className="user-message-bubble">{message.text}</span>
                )}
              </div>
            ))}
            {error && (
              <div className="bot-message-container w-full flex items-start mb-4">
                <div className="bot-message-content text-red-600 text-sm rounded-lg p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}
          </div>
          <div className="dummy-container flex flex-col gap-2 md:gap-0 mr-20 ">
            {showDummyMessages &&
              typedDummyMessages.map((question, index) => (
                <div
                  key={index}
                  className={`dummy-message-box border border-purple-400 rounded-lg p-4 mt-4 flex items-center cursor-pointer ${
                    clickedIndex === index ? "opacity-50" : "opacity-100"
                  }`}
                  onClick={() =>
                    handleDummyMessageClick(question.trim(), index)
                  }
                >
                  <span className="icon mr-2">
                    <img src="/star-dot.svg" alt="Star Icon" />
                  </span>
                  <p className="text-sm">{question.trim()}</p>
                </div>
              ))}
          </div>
          <div className="custom-input-wrapper ">
  <div className="input-container-holder">
  <textarea
  placeholder={isSummaryLoading ? "Summary is loading..." : "Ask me anything"}
  className="input-field-holder w-4/5 min-h-[22px] resize-none text-[#3f3f46] font-outfit text-lg font-semibold leading-6 ml-[35px] mt-[17px] border-0 outline-none bg-transparent overflow-hidden max-h-[88px] overflow-y-auto"
  value={clientMessage}
  onChange={(e) => {
    if (!isAwaitingResponse && !isSummaryLoading) {
      setClientMessage(e.target.value);
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${Math.min(
          e.target.scrollHeight,
          88
        )}px`;
      }
    }
  }}
  onKeyDown={(e) => {
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      !isAwaitingResponse 
      && !isSummaryLoading    
    ) {
      e.preventDefault();
      if (clientMessage.trim()) {
        handleSend(clientMessage.trim());
        setClientMessage("");
      }
    }
  }}
  ref={textareaRef}
  disabled={isAwaitingResponse || isSummaryLoading}
></textarea>




<button
  className="send-button-holder"
  onClick={() => {
    setTextAreaHeight("15px");
    handleSend();
  }}
  disabled={isAwaitingResponse || isSummaryLoading}
>
<img
        src={
          isSummaryLoading
            ? "/loading-send-button.svg"
            : isAwaitingResponse
            ? "/loading-send-button.svg"
            : "/send.svg"
        }
        alt="Send"
      />
</button>

  </div>
</div>


        </div>
        <div className="right-side">
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            className="w-full h-full"
            allow="fullscreen"
          />
        </div>
      </div>
      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            <div>
              <div>
                <button
                  className="close-button-thumsdown"
                  onClick={() => setShowThumbsDownPopup(false)}
                >
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed
                  your question or concern.
                </p>
                <div className="checkbox-container-thumsup">
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup1"
                      id="factuallyIncorrect"
                      checked={selectedCheckboxes.factuallyIncorrect}
                      onChange={handleCheckboxChange}
                    />
                    Not factually correct.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup2"
                      id="refused"
                      checked={selectedCheckboxes.refused}
                      onChange={handleCheckboxChange}
                    />
                    Refused when it shouldn’t have.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup3"
                      id="notFollowingInstructions"
                      checked={selectedCheckboxes.notFollowingInstructions}
                      onChange={handleCheckboxChange}
                    />
                    Not Following Instructions.
                  </label>
                </div>
                <div className="line-with-text">
                  <span>Or</span>
                </div>
                <div className="rectangular-box">
                  <div className="feedback-container">
                    <textarea
                      className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                      maxLength="250"
                      placeholder="Your feedback is much appreciated and helps improve the AI!"
                      value={feedbackMessage}
                      onChange={(e) => setFeedbackMessage(e.target.value)}
                    />
                  </div>
                  <p className="character-count">Maximum: 250 characters</p>
                </div>
                <button
                  className={`custom-button-thumsdown ${
                    isSubmitDisabled() ? "disabled-button-thumsdown" : "enabled-button-thumsdown"
                  }`}
                  onClick={handleSubmitThumbsDownFeedback}
                  disabled={isSubmitDisabled()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PdfChat;
