import { googleLogin } from "../services/login";
import { FcGoogle } from "react-icons/fc";
import { Link, Navigate, useNavigate } from "react-router-dom";

function GoogleSignupButton(props) {

  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  /*global google*/
  let client;
  
  const handleGoogleSignup = async (response) => {
    console.log(response, "response");

    try {
      // Call googleLogin service to handle the signup and retrieve the user data
      const res = await googleLogin(response.code, hostname);

      // Assuming the email is returned in the response (adjust according to your API)
      const email = res.data?.email; 
      const username = res.data?.username;
      if (email) {
        localStorage.setItem("email", email); // Save email to localStorage
      }
      if (username) {
        localStorage.setItem("username", username); // Save email to localStorage
      }
      // Continue with login success actions
      props.setVerified(true);
      props.loginMessage();
      navigate("/ai-copilot");
    } catch (error) {
      console.error("Google signup failed", error);
    }
  };

  if (typeof google !== "undefined") {
    console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID);
    console.log("It is in if condition", process.env.REACT_APP_GOOGLE_CLIENT_ID);
    
    client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope:
        "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
      callback: handleGoogleSignup,
    });
  } else {
    console.log("It is else if condition");
    async function wait_for_1_sec() {
      await delay(1000);
      client = google.accounts.oauth2.initCodeClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope:
          "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
        callback: handleGoogleSignup,
      });
    }
    wait_for_1_sec();
  }


  // const hostname = window.location.hostname;
  // const navigate = useNavigate();
  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  // /*global google*/
  // let client;
  // if (typeof google != "undefined") {
  //   console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID); 
  //   console.log('It is in if condition', process.env.REACT_APP_GOOGLE_CLIENT_ID)
  //   client = google.accounts.oauth2.initCodeClient({
  //     client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      
  //     scope:
  //       "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
  //     callback: async (response) => {
  //       await googleLogin(response.code, hostname).then((res) => {
  //         props.setVerified(true);
  //         props.loginMessage();
  //         navigate("/uploadFile")
  //       });
  //     },
  //   });
  //   console.log(client,'success')
  // } else {
  //   console.log('It is else if condition')
  //   async function wait_for_1_sec() {
  //     await delay(1000);
  //     client = google.accounts.oauth2.initCodeClient({
  //       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //       scope:
  //         "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",

  //       callback: async (response) => {
  //         await googleLogin(response.code, hostname).then((res) => {
  //           props.setVerified(true);
  //           props.loginMessage();
  //           navigate("/uploadFile")
  //         });
  //       },
  //     });
  //   }
  //   wait_for_1_sec();
  // }

  return (
    <button
      id="google-login-btn"
      onClick={() => client.requestCode()}
      className="googlebtn flex w-full items-center"
    >
      <div className="google-icon bg-white h-full w-16 flex justify-center items-center">
        <FcGoogle />
      </div>
      <div className="googleText flex justify-center items-center">
        <div className=" ">Signin with your google Account</div>
      </div>
    </button>
  );
}
export default GoogleSignupButton;
