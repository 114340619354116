import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate , useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './otp.css'
import close from '../../../Images/close.jpg'
import verifylogo from "../../../Images/Succes-email.png"
const OtpPage = ({ closeModal, setEmailAccountVerified }) => {

  const location = useLocation(); 
  
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState('');
  const [verify, setVerify] = useState(false)
  const [timeLeft, setTimeLeft] = useState(30); 
  const [isResendDisabled, setIsResendDisabled] = useState(true); 

  const inputs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/signup"); 
  };



  useEffect(() => {
   
    const emailFromState = location.state?.email;
    console.log("Email from state:", emailFromState);
    
    if (emailFromState) {
      setEmail(emailFromState);
    } else {
      navigate("/signup"); 
    }
  
    
    if (inputs[0].current) {
      inputs[0].current.focus();
    }
  
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer); 
          setIsResendDisabled(false); 
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  
    
    return () => clearInterval(timer);
  
  }, [location.state, navigate, timeLeft]); 
  
  const token = localStorage.getItem('token');
  console.log("shdshdsjd",token);
 

  const handleInput = (e, index) => {
    const input = e.target;
    if (index < inputs.length - 1 && input.value !== "") {
      inputs[index + 1].current.focus();
    }
    const newOtp = otp.slice(0, index) + input.value + otp.slice(index + 1);
    setOtp(newOtp);
  };
  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("Text");
    if (pastedData.length === inputs.length) {
      
      pastedData.split("").forEach((char, idx) => {
        if (inputs[idx]) {
          inputs[idx].current.value = char; 
        }
      });
      setOtp(pastedData); 
      e.preventDefault();
    }
  };
  

  

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && index > 0 && e.target.value === "") {
      inputs[index - 1].current.focus();
    }
  };
  const handleNavigation = () => {
    navigate("/uploadFile"); 
  };

  useEffect(() => {
    if (verify) {
      navigate("/uploadFile");
    }
  }, [verify, navigate]); 

  useEffect(() => {
    const isOtpRequired = localStorage.getItem("otp_verification_required");
    if (!isOtpRequired) {
      navigate("/signup", { replace: true });
    }
  }, [navigate]);
  
  
  const handleVerify = async () => {
    console.log("Email:", email);
    console.log("OTP:", otp);
  
    const data = {
      email: email,
      otp: otp,
    };
  
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/accounts/verify/",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log("OTP verification response:", response);
  
      if (response.data.message === "Account verified successfully.") {
        toast.success("Account verified successfully!", {
          autoClose: 1000,
        });
  
        // Remove OTP-related flags from localStorage
        localStorage.removeItem("otp_verification_required");
  
        // Navigate to the upload file page
        navigate("/uploadFile");
      } else if (response.data.data === "Wrong OTP") {
        toast.warn("Wrong OTP", {
          autoClose: 2000,
        });
      } else if (response.data.data === "Invalid email") {
        toast.warn("Invalid email", {
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error while verifying OTP:", error);
      toast.error("Failed to verify OTP. Please try again.");
    }
  };
  
  const restartTimer = () => {
    setTimeLeft(30); 
    setIsResendDisabled(true); 
  };

  console.log("token",token);
  
  const handleResendOTP = async () => {
    if (isResendDisabled) return; 
    
    try {
      const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
        "Content-Type": "application/json",
      };
  
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/accounts/resend-otp/",
        null,
        { headers }
      );
  
      if (response.data.message === "New OTP sent to your email address.") {
        toast.success("OTP resent successfully", { autoClose: 1000 });
  
      
        setOtp("");
        inputs.forEach((input) => (input.current.value = ""));
  
        
        restartTimer();
      } else if (response.data.message === "Your account is already verified.") {
        toast.info("Your account is already verified", { autoClose: 1000 });
      }
    } catch (error) {
      console.error("Error while resending OTP:", error);
    }
};

  
  
  
  return (
<div className="otp-fullContainer relative">
  
  <div className="w-[164.713px] h-[30px] flex-shrink-0 absolute top-[61px] left-[661px] bg-#FFF">
    <img
      src="/docusensasign.svg"
      alt="docuSensa logo"
      className="w-full h-full"
    />
  </div>
    <div className="absolute main-otppage z-50">
      <div className=" flex items-center justify-center ">

    {verify ? (
      <div className="otpverify-container relative bg-white h-auto text-center">
        <div>
          <div className="flex justify-center items-center mt-8 ">
            <img src={verifylogo} alt="email verify logo" className="w-[245px] h-[98px]"/>
          </div>
          <div className="mt-3">
              <h2 className="verify-topHeading">Your E-mail has been Verified</h2>
              <p className="verify-subHeading">Your email has been successfully verified.</p>
          </div>
          <div>
          <button
                    onClick={handleNavigation} 
                    className="close-verifybtn text-white text-center mt-9"
                  >
            Close
          </button>
          </div>
        </div>
      </div>
    ) : (
      <div className="otp-container relative  h-auto text-center">
          <div className="text-left">
            <img src="/otp-pageIcon.svg" alt="" className="otp-PageIcon"/>
            <h1 className="top-heading">Verify Email </h1>
            <div className="sub-heading w-[415px] mt-4">
  <p>
    Please enter the 6 digit Verification code that has been sent to { }
    {email.replace(/(^.{2})(.*)(@.*)/, "$1******$3")}
  </p>
</div>
          </div>
        
          

          <div className="otp-box-cont flex flex-row gap-3 items-center">
            {inputs.map((inputRef, index) => (
              <input
                key={index}
                className="otp-box"
                type="text"
                ref={inputRef}
                maxLength="1"
                value={otp[index] || ""}
                onChange={(e) => handleInput(e, index)}
                onKeyDown={(e) => handleBackspace(e, index)}
                onPaste={(e) => handlePaste(e)}

              />
            ))}
          </div>

          <div>
  {isResendDisabled ? (
    <button disabled className="resend-btn flex justify-end mt-[15px] resent-btn">
Resend OTP in {Math.floor(timeLeft / 60).toString().padStart(2, '0')}:{(timeLeft % 60).toString().padStart(2, '0')}
</button>
  ) : (
    <button onClick={handleResendOTP} className="otp-resend">
      <span className="otp-text">Did not receive OTP?</span>
      <span className="otp-resend-text"> Resend OTP</span>
    </button>
  )}
</div>




          <div className="flex justify-start ml-8 mt-4">
            <button
              onClick={handleVerify}
              className="verify-btn text-center"
            >
              Verify E-mail
            </button>
          </div>

         
          
        </div>
        
    )}

        
      </div>
      <p className="wrong-email">Wrong Email id? <button onClick={handleGoBack} className="go-back-button">
  <span>Go back</span>
</button></p>
    </div>
    </div>
  );
};

export default OtpPage;