import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import UploadModal from "./UploadModal";
import FilterModal from "./filterModal";
import bell from "../../Images/ai-bell.png";
import search from "../../Images/search-big.jpg";
import axios from "axios";
import "./contract_data.css";
import Spinner from "./Spinner";

const ContractTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [token, setToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(null);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [editContractId, setEditContractId] = useState(null);
  const [editedContractData, setEditedContractData] = useState({});
  const [loading, setLoading] = useState(false);
  const editRowRef = useRef(null);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [selectedContractKeys, setSelectedContractKeys] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [contractToDelete, setContractToDelete] = useState(null);
  const actionMenuRef = useRef(null);
  const [hoveredRow, setHoveredRow] = useState(null);

  const [filterApplied, setFilterApplied] = useState(false);
  const navigate = useNavigate();
  const handleFilterApply = (filteredContracts) => {
    setContracts(filteredContracts);
    setFilterApplied(true);
    toggleFilterModal();
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error("Token is missing from localStorage");
    }
  }, []);

  const toggleModal = () => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }
    setIsModalOpen((prev) => !prev);
  };

  const handleExport = async () => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    if (!selectedContracts || selectedContracts.length === 0) {
      showPopup("Error", "Please select at least one contract to export.");
      return;
    }

    setExportInProgress(true);

    try {
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/export-file/`,
        { contract_keys: selectedContractKeys },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contracts_export.csv");
      document.body.appendChild(link);
      link.click();

      showPopup("Success", "Contracts exported successfully.");
    } catch (error) {
      if (error.response) {
        const message =
          error.response.data.msg || "Failed to export contracts.";
        showPopup("Error", message);
      } else {
        showPopup("Error", "An unexpected error occurred. Please try again.");
      }
    } finally {
      setExportInProgress(false);
    }
  };

  const handleCancel = () => {
    setSelectedContracts([]);

    setIsModalOpen(false);
    setIsFilterModalOpen(false);
    setPopupVisible(false);

    setConfirmDeleteVisible(false);
  };

  const fetchContracts = useCallback(async () => {
    if (!token) return;
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/contracts/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log("response", response.data);
      setContracts(response.data);
    } catch (error) {
      console.error("Error fetching contracts:", error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const tableBodyRef = useRef(null);

  const scrollToTop = () => {
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollTop = 0;
    }
  };

  const handleSelectAll = () => {
    if (selectedContracts.length === contracts.length) {
      setSelectedContracts([]);
      setSelectedContractKeys([]);
    } else {
      setSelectedContracts(contracts.map((contract) => contract.id));
      setSelectedContractKeys(contracts.map((contract) => contract.key));
    }
  };
  
  const filteredContracts = useMemo(() => {
    const query = searchQuery?.toLowerCase() || "";
    return contracts.filter((contract) => {
      const contractName = contract?.contract_name?.toLowerCase() || "";
      const ownerName = contract?.contract_owner_name?.toLowerCase() || "";
      const customerName = contract?.customer_name?.toLowerCase() || "";

      return (
        contractName.includes(query) ||
        ownerName.includes(query) ||
        customerName.includes(query)
      );
    });
  }, [contracts, searchQuery]);

  const indexOfLastContract = currentPage * rowsPerPage;
  const indexOfFirstContract = indexOfLastContract - rowsPerPage;
  const currentContracts = useMemo(() => {
    const indexOfLastContract = currentPage * rowsPerPage;
    const indexOfFirstContract = indexOfLastContract - rowsPerPage;
    return filteredContracts.slice(indexOfFirstContract, indexOfLastContract);
  }, [filteredContracts, currentPage, rowsPerPage]);

  const totalPages = useMemo(
    () => Math.ceil(filteredContracts.length / rowsPerPage),
    [filteredContracts, rowsPerPage]
  );
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleCheckboxChange = (id, key) => {
    if (selectedContracts.includes(id)) {
      setSelectedContracts(selectedContracts.filter((contractId) => contractId !== id));
      setSelectedContractKeys(selectedContractKeys.filter((contractKey) => contractKey !== key));
    } else {
      setSelectedContracts([...selectedContracts, id]);
      setSelectedContractKeys([...selectedContractKeys, key]);
    }
  };
  

  useEffect(() => {
    if (token) {
      fetchContracts();
    }
  }, [fetchContracts, token]);

  const handleUploadSuccess = () => {
    fetchContracts();
    toggleModal();
  };

  const handleCloseModal = () => {
    toggleModal();
    fetchContracts();
  };

  const handlePageChange = (event) => {
    const newPage = Number(event.target.value);
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const toggleActionMenu = (index) => {
    setIsActionMenuOpen(isActionMenuOpen === index ? null : index);
  };

  const toggleFilterModal = () => {
    setIsFilterModalOpen((prev) => !prev);
  };

  const handleViewMore = (contractKey) => {
    const condition = "table";
    navigate("/Contract/contract-details", { state: { contractKey } });
  };

  const enableEdit = (contract) => {
    if (contract) {
      setIsActionMenuOpen(false);
      setEditContractId(contract.id);
      setEditedContractData({ ...contract, details: { ...contract.details } });
    } else {
      setEditContractId(null);
      setEditedContractData({});
    }
  };
  

  const handleEditChange = (field, value) => {
    if (field.startsWith("details.")) {
      const key = field.split(".")[1];
      setEditedContractData((prev) => ({
        ...prev,
        details: {
          ...prev.details,
          [key]: value,
        },
      }));
    } else {
      setEditedContractData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };
  

  const handleClickOutside = (event) => {
    if (
      actionMenuRef.current &&
      !actionMenuRef.current.contains(event.target)
    ) {
      setIsActionMenuOpen(null);
    }
    if (
      confirmDeleteVisible &&
      event.target.closest(".popup-overlay") === null &&
      !event.target.closest(".popup-box-delete")
    ) {
      setConfirmDeleteVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [confirmDeleteVisible, isActionMenuOpen]);

  const showPopup = (title, message) => {
    setPopupTitle(title);
    setPopupMessage(message);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };
  const handleDelete = (contractId) => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }
    setIsBulkDelete(false);
    setContractToDelete(contractId);
    setConfirmDeleteVisible(true);
  };

  const handleBulkDelete = () => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    if (!selectedContracts || selectedContracts.length === 0) {
      showPopup("Error", "Please select at least one contract to delete.");
      return;
    }

    setIsBulkDelete(true);
    setConfirmDeleteVisible(true);
  };

  const handleEditSave = async (contractId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/edit-contract-name/`,
        {
          contract_key: editedContractData.key,
          new_contract_name: editedContractData.details["Contract Title"],
          party_a: editedContractData.details["Client name"], 
        party_b: editedContractData.details?.["Company Name"],
        effective_date: editedContractData.details?.["Effective Date"],
        exp_date: editedContractData.details?.["Expiration Date"],
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        const updatedContract = response.data; 
        setContracts((prevContracts) =>
          prevContracts.map((contract) =>
            contract.id === contractId ? updatedContract : contract
          )
        );
        setEditContractId(null);
        showPopup("Success", "Contract updated successfully.");
        fetchContracts();
      }
    } catch (error) {
      console.error("Error saving contract:", error);
      showPopup("Error", "Failed to update contract. Please try again.");
    }
  };
  

  const confirmDelete = async () => {
    if (!token) {
      showPopup("Error", "Session expired. Please log in again.");
      return;
    }

    const idsToDelete = isBulkDelete ? selectedContracts : [contractToDelete];

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/delete-contract/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          data: { contract_ids: idsToDelete },
        }
      );

      const { Deleted_contracts = [], Failed_deletions = [] } = response.data;

      if (Deleted_contracts.length > 0) {
        setContracts((prevContracts) =>
          prevContracts.filter(
            (contract) => !Deleted_contracts.includes(contract.id)
          )
        );

        if (isBulkDelete) setSelectedContracts([]);

        showPopup(
          "Success",
          `${Deleted_contracts.length} contract(s) deleted successfully.`
        );
      }

      if (Failed_deletions.length > 0) {
        const failureMessages = Failed_deletions.map(
          (failure) => `Contract ID ${failure.contract_id}: ${failure.error}`
        ).join("\n");
        showPopup("Deletion Failed", failureMessages);
      }
    } catch (error) {
      console.error("Error deleting contracts:", error);
      showPopup("Error", "Failed to delete contract(s). Please try again.");
    } finally {
      setConfirmDeleteVisible(false);
      setContractToDelete(null);
      fetchContracts();
    }
  };

  useEffect(() => {
    if (editContractId) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editContractId]);

  return (
    <>
      {loading && <Spinner />}
      <div className="full-container">
        <div className="">
          <Header name="Contracts" icon={""} />
        </div>

        <div className="contract-table-container">
          <div className="contract-table-header">
            <div className="search-filter-container">
              <div className="search-box">
                <img src="search.svg" alt="" />
                <input
                  type="text"
                  placeholder="Search Contracts Here"
                  className="Input-space"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <button className="filter-button" onClick={toggleFilterModal}>
                <img src="/filter.svg" alt="Filter Icon" />
              </button>
            </div>
            {selectedContracts.length > 0 ? (
              <div className="button-cat">
                <button
                  onClick={handleExport}
                  disabled={exportInProgress || selectedContracts.length === 0}
                >
                  <img src="./export-icon-blue.svg" alt="Export Icon" />
                  {exportInProgress ? "Exporting..." : "Export"}
                </button>
                <button
                  onClick={handleBulkDelete}
                  disabled={selectedContracts.length === 0}
                >
                  <img src="./delete-icon-red.svg" alt="Delete Icon" />
                  Delete
                </button>

                <button onClick={handleCancel} className="cancel-bulk">
                  <img src="/cancel_icon.svg" alt="Cancel Icon" />
                </button>
              </div>
            ) : (
              <button className="upload-button" onClick={toggleModal}>
                <img
                  className="upload-icon"
                  src="/upload_icon.svg"
                  alt="Upload Icon"
                />
                <span className="upload-text">Upload Contract</span>
              </button>
            )}
          </div>

          <div className="container">
            <table className="contract-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      onChange={handleSelectAll}
                      checked={
                        selectedContracts.length === contracts.length &&
                        contracts.length > 0
                      }
                    />
                  </th>
                  {/* <th>Contract ID</th> */}
                  <th>Contract Name</th>
                  <th>Party Involved (A)</th>
                  <th>Party Involved (B)</th>
                  <th>Effective Date</th>
                  <th>Expiration Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody
                className={
                  currentContracts.length > rowsPerPage ? "scrollable" : ""
                }
                style={{
                  overflowY:
                    currentContracts.length > rowsPerPage ? "auto" : "hidden",
                  maxHeight: "400px",
                }}
              >
                {loading ? (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      <Spinner />
                    </td>
                  </tr>
                ) : contracts.length > 0 ? (
                  currentContracts.map((contract, index) => (
                    <tr
                      key={index}
                      ref={editContractId === contract.id ? editRowRef : null}
                      style={{
                        background:
                          hoveredRow === index ? "#F8F3FF" : "transparent",
                        boxShadow:
                          hoveredRow === index
                            ? "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
                            : "none",
                      }}
                      onMouseEnter={() => setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      <td>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          checked={selectedContracts.includes(contract.id)}
                          onChange={() => handleCheckboxChange(contract.id, contract.key)}
                          />
                      </td>
                      
                      {editContractId === contract.id ? (
                        <>
                          <td>
                            <input
                              type="text"
                              value={editedContractData.details?.["Contract Title"] || ""}
                              onChange={(e) =>
                                handleEditChange(
                                  "details.Contract Title",
                                  e.target.value
                                )
                              }
                              className="edit-contract"
                              style={{ color: "#B793ED" }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={
                                editedContractData.details?.["Client name"] ||
                                ""
                              }
                              onChange={(e) =>
                                handleEditChange(
                                  "details.Client name",
                                  e.target.value
                                )
                              }
                              className="edit-contract"
                            />
                          </td>

                          <td>
    <input
      type="text"
      value={editedContractData.details?.["Company Name"] || ""}
      onChange={(e) =>
        handleEditChange("details.Company Name", e.target.value)
      }
      className="edit-contract"
    />
  </td>
  <td>
    <input
      type="date"
      value={editedContractData.details?.["Effective Date"] || ""}
      onChange={(e) =>
        handleEditChange("details.Effective Date", e.target.value)
      }
      className="edit-contract"
    />
  </td>
  <td>
    <input
      type="date"
      value={editedContractData.details?.["Expiration Date"] || ""}
      onChange={(e) =>
        handleEditChange("details.Expiration Date", e.target.value)
      }
      className="edit-contract"
    />
  </td>
                          <td>
                            <button
                              onClick={() => handleEditSave(contract.id)}
                              className="save_Design"
                            >
                              Save
                            </button>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            <span
                              style={{
                                color: "#B793ED",
                                textDecoration: "none",
                                cursor: "pointer",
                                borderBottom: "1.5px solid #B793ED",
                                paddingBottom: "2px",
                              }}
                              onClick={() => handleViewMore(contract.key)}
                            >
                              {contract.details?.["Contract Title"] || "N/A"}
                            </span>
                          </td>
                          <td>{contract.details?.["Client name"] || "N/A"}</td>
                          <td>{contract.details?.["Company Name"] || "N/A"}</td>
                          <td>{contract.details?.["Effective Date"] || "N/A"}</td>
                          <td>{contract.details?.["Expiration Date"] || "N/A"}</td>
                          <td>
                            <img
                              src="./action.svg"
                              alt="Actions"
                              width="20"
                              height="20"
                              onClick={() => toggleActionMenu(index)}
                              style={{ cursor: "pointer" }}
                            />
                            {isActionMenuOpen === index && (
                              <div className="action-menu" ref={actionMenuRef}>
                                <div
                                  className="action-item"
                                  onClick={() => enableEdit(contract)}
                                >
                                  <img
                                    src="./edit.svg"
                                    alt="Edit Icon"
                                    className="action-icon"
                                  />
                                  <p>Edit</p>
                                </div>
                                <div
                                  className="action-item"
                                  onClick={() => handleDelete(contract.id)}
                                >
                                  <img
                                    src="./delete.svg"
                                    alt="Delete Icon"
                                    className="action-icon"
                                  />
                                  <p>Delete</p>
                                </div>
                              </div>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  ))
                ) : (
                  <div className="no-contracts-icon">
                    {filterApplied ? (
                      <>
                        <img src="/no-contracts.svg" alt="No Contracts Found" />
                        <p className="no-contracts-para">
                          No contracts found for the selected filters.
                        </p>
                      </>
                    ) : (
                      <>
                        <img
                          src="/no-contracts.svg"
                          alt="No Contracts Available"
                        />
                        <p className="no-contracts-para">
                          Looks like your table is empty!
                        </p>
                      </>
                    )}
                  </div>
                )}
              </tbody>
            </table>
            {contracts.length > 0 && (
              <div className="pagination">
                <div className="pagination-dropdown">
                  <select
                    value={currentPage}
                    onChange={(e) => setCurrentPage(Number(e.target.value))}
                    className="page-selector"
                  >
                    {Array.from({ length: totalPages }, (_, index) => (
                      <option key={index} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <p className="contentDown">of {totalPages} pages</p>
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  style={{
                    cursor: currentPage === 1 ? "not-allowed" : "pointer",
                  }}
                >
                  <img
                    src="decrease.svg"
                    alt="Previous Page"
                    style={{ height: "12px", width: "12px" }}
                  />
                </button>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  style={{
                    cursor:
                      currentPage === totalPages ? "not-allowed" : "pointer",
                  }}
                >
                  <img
                    src="increase.svg"
                    alt="Next Page"
                    style={{ height: "12px", width: "12px" }}
                  />
                </button>
              </div>
            )}
          </div>
        </div>

        {popupVisible && (
          <div className="popup-overlay">
            <div className="popup-box">
              <h2>{popupTitle}</h2>
              <p>{popupMessage}</p>
              <button onClick={closePopup} className="popup-close-button">
                Close
              </button>
            </div>
          </div>
        )}

        {confirmDeleteVisible && (
          <div className="popup-overlay">
            <div className="popup-box-delete">
              <h2>Confirm Deletion</h2>
              <p>
                {isBulkDelete
                  ? "Are you sure you want to delete the selected contracts? This action cannot be undone."
                  : "Are you sure you want to delete this contract? This action cannot be undone."}
              </p>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={confirmDelete}
                  className="popup-close-button-confirm"
                >
                  Yes, Delete
                </button>
                <button
                  onClick={() => setConfirmDeleteVisible(false)}
                  className="popup-close-button-delete"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <UploadModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onUploadSuccess={handleUploadSuccess}
          type={"contract"}
        />

        <FilterModal
          isOpen={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          token={token}
          setContracts={(filteredContracts) => {
            setContracts(filteredContracts);
            setIsFilterModalOpen(false);
          }}
        />
      </div>
    </>
  );
};

export default ContractTable;
